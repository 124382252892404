<template>
  <div class="hold-transition">
    <div class="modal fade" id="Modal_firmas">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Aprobación Liquidación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalFirmas"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div
                class="col-md-4"
                v-for="(firmante, index) in formFirmas"
                :key="index"
              >
                <div class="card card-widget widget-user shadow">
                  <div class="widget-user-bg-header bg-frontera text-white">
                    <button
                      type="button"
                      class="close text-white"
                      @click="correoFirmasInd(firmante)"
                    >
                      <i class="fas fa-envelope"></i>
                    </button>
                    <p style="font-size: 15px" class="p-2 text-center">
                      {{ firmante.userFirma.toUpperCase() }}
                      <br />
                      {{ firmante.nombre_cargo }}
                    </p>
                  </div>
                  <div class="widget-user-link_pho">
                    <a target="_blank">
                      <img
                        v-if="firmante.link_photography === null"
                        class="img-fluid img-circle elevation-2"
                        src="/img/perfil_vacio.png"
                        style="height: 128px; width: 128px"
                        alt="User profile picture"
                      />

                      <img
                        v-else
                        class="profile-user-img img-fluid img-circle elevation-2"
                        :src="uri_docs + firmante.link_photography"
                        style="height: 128px; width: 128px"
                        alt="User profile picture"
                        :href="uri_docs + firmante.link_photography"
                      />
                    </a>
                  </div>
                  <div class="card-body pt-5 mt-5">
                    <div
                      class="row"
                      v-if="
                        !firmante.fecha_firma &&
                          firmante.userCargoId == $parent.user.id &&
                          $parent.user.link_firma
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              {{
                                $store.getters.getAuthAzure
                                  ? `La sesión esta iniciada con Azure.`
                                  : `Para aprobar el servicio, por favor digite su clave.`
                              }}
                            </strong></small
                          >
                        </div>
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="sign_password">Contraseña</label>
                        <input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas[index].pass"
                          @change="validationForm()"
                        />
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="re_sign_password"
                          >Confirme Contraseña</label
                        >
                        <input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="formFirmas[index].re_pass"
                          @change="validationForm()"
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label>
                        <textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="formFirmas[index].observaciones"
                        ></textarea>
                      </div>
                      <div
                        class="col-md-12"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <button
                          type="button"
                          title="Guardar firma"
                          data-toggle="tooltip"
                          class="btn btn-sm bg-success"
                          @click="saveFirma(index)"
                          v-show="indexFirma == 2"
                        >
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Servicio</small></span>
                          </div>
                        </button>
                      </div>
                      <div class="col-md-12" v-else>
                        <button
                          type="button"
                          title="Guardar firma"
                          data-toggle="tooltip"
                          class="btn btn-sm bg-success"
                          @click="saveFirma(index)"
                        >
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Servicio</small></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-else-if="
                        !$parent.user.link_firma &&
                          firmante.userCargoId == $parent.user.id
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning text-center">
                          <small
                            ><strong>
                              <i>Para firmar debe tener una firma digital</i>
                            </strong></small
                          >
                        </div>
                      </div>
                    </div>
                    <!-- Cuando ya está firmado -->
                    <div class="row" v-else-if="firmante.fecha_firma">
                      <div class="col-md-12">
                        <div>
                          <b v-if="firmante.user.link_firma">
                            <img
                              class="img-fluid"
                              :src="uri_docs + firmante.user.link_firma"
                            />
                          </b>
                        </div>
                        <hr />
                        <div>
                          <b> Firmado Por: {{ firmante.userFirma }}</b>
                        </div>
                        <div>
                          <b> Firmado: {{ firmante.fecha_firma }}</b>
                        </div>
                        <div>
                          <b> Observación: {{ firmante.observaciones }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-sm bg-gradient-info"
              v-if="$store.getters.can('tif.liquidaciones.print')"
              @click="$parent.verPDF(id)"
            >
              <i class="fas fa-print"> Imprimir Liquidación</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "TifLiquidacionesFirmas",
  data() {
    return {
      id: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      firmas: [],
      modalTitle: null,
      formFirmas: [],
      indexFirma: 0,
      liquidacion: {},
      listasForms: {
        cargos: [],
      },
    };
  },

  methods: {
    async getDataFirmas(liquidacion) {
      let me = this;
      me.$parent.cargando = true;
      me.formFirmas = [];
      me.indexFirma = 0;
      me.id = liquidacion.id;
      me.liquidacion = liquidacion;
      me.modalTitle = `Aprobación de Liquidación`;
      //Cargar las firmas ya realizadas
      me.firmas = liquidacion.tif_liquidacion_firmas;

      //GetCargos
      await axios({
        method: "GET",
        url: "/api/tif/liquidaciones/getFirmasLiqui/",
        params: {
          tif_liquidacion_id: liquidacion.id,
        },
      })
        .then((response) => {
          me.listasForms.cargos = response.data.cargos_liqui;
          me.llenarModal();
          me.$parent.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.$parent.cargando = false;
        });
      me.llenarFirmar();
    },

    llenarFirmar() {
      let cambio = [];
      let me = this;
      let arregloAgregar = [];
      me.formFirmas.forEach(async function(element, index) {
        if (me.firmas.length > 0) {
          me.firmas.forEach(async (firma) => {
            if (element.userCargoId == firma.user_id) {
              me.formFirmas[index].user = firma.user;
              me.formFirmas[index].user_id = firma.user_id;
              me.formFirmas[index].fecha_firma = firma.fecha_firma;
              me.formFirmas[index].observaciones = firma.observaciones;
            }
            if (!arregloAgregar.includes(element.userCargoId)) {
              arregloAgregar.push(element.userCargoId);
              cambio.push(element);
            }
          });
        } else {
          cambio.push(element);
        }
      });
      me.formFirmas = cambio;
    },

    llenarModal() {
      let llenar = [];
      for (let i = 0; i < this.listasForms.cargos.length; i++) {
        llenar = {
          cargo: this.listasForms.cargos[i].cargo,
          nombre_cargo: this.listasForms.cargos[i].nCargo,
          userCargoId: this.listasForms.cargos[i].usuario_id,
          userFirma: this.listasForms.cargos[i].user.name,
          fecha_firma: null,
          tif_liquidacion_id: this.id,
          pass: null,
          re_pass: null,
          observaciones: null,
          link_photography: this.listasForms.cargos[i].user.link_photography,
          anti_reenvio_fecha: this.listasForms.cargos[i].pivot.reenvio_fecha,
          tif_cargo_operacion_id: this.listasForms.cargos[i].pivot
            .tif_cargo_operacion_id,
        };
        this.formFirmas.push(llenar);
      }
    },

    saveFirma(i) {
      let me = this;
      me.$parent.cargando = true;
      me.formFirmas[i].loginAzure = me.$store.getters.getAuthAzure;
      axios
        .post("/api/tif/liquidaciones/saveFirma", me.formFirmas[i])
        .then((response) => {
          if (!response.data.user_error) {
            me.$parent.cargando = false;
            me.$parent.getIndex();
            me.$refs.closeModalFirmas.click();
            me.$swal({
              icon: "success",
              title: "Se Firmo Exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            me.$parent.cargando = false;
            me.$swal({
              icon: "error",
              title: response.data.user_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          me.$parent.cargando = false;
        });
    },

    validationForm() {
      this.indexFirma++;
    },

    correoFirmasInd(firmante) {
      this.$parent.cargando = true;
      let data = {
        tif_liquidacion_id: firmante.tif_liquidacion_id,
        nombre_cargo: firmante.nombre_cargo,
        user_id: firmante.userCargoId,
        reenvio_fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
        anti_reenvio_fecha: firmante.anti_reenvio_fecha,
        tif_cargo_operacion_id: firmante.tif_cargo_operacion_id,
        accion: 2,
      };

      axios
        .get("/api/tif/liquidaciones/enviarCorreoFirmas", {
          params: data,
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.$parent.getIndex();
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
<style>
.widget-user-link_pho {
  left: 50%;
  margin-left: -68px;
  position: absolute;
  top: 90px;
}
.widget-user-bg-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 130px;
  padding: 1rem;
  text-align: center;
}
</style>
